import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const Button = loadable(() => import('/src/components/Button'))

const SubmitButton = ({ initialText, sentText, isSubmitting, isSubmitted }) => {
    return (
        <Button
            type="submit"
            size="lg"
            className={`${
                isSubmitting
                    ? ' btn-primary btn-submitting'
                    : isSubmitted
                    ? 'btn-success btn-check'
                    : 'btn-primary btn-arrow'
            }`}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
        >
            {isSubmitting ? 'Sending...' : isSubmitted ? sentText : initialText}
        </Button>
    )
}

SubmitButton.propTypes = {
    /**
     * Initial button text
     */
    initialText: PropTypes.string.isRequired,
    /**
     * Submitted button text
     */
    sentText: PropTypes.string.isRequired,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired
}

SubmitButton.defaultProps = {
    initialText: 'Send enquiry',
    sentText: 'Sent!',
    isSubmitting: false,
    isSubmitted: false
}

export default SubmitButton
